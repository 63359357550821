import { graphql, useStaticQuery } from "gatsby"
import React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"

const FaqPage = (  ) => {
     const pageQuery = useStaticQuery(graphql`
        query faqs {
            allWpFaq(sort: { fields: [date], order: ASC }) {
                nodes {
                    title
                    slug
                    content
                    seo {
                        metaDesc
                        title
                    }
                }
            }
        }
    `)
    const faqs = pageQuery.allWpFaq.nodes
    
    return (
        <div>
            <Seo title={pageQuery.seo?.title ? pageQuery.seo.title  : 'Frequently Asked Questions' } description={pageQuery.seo?.metaDesc} />

            <Layout>
                <section class="faqs blog_intro">
                    <div class="container">
                        <a class="btn blog_intro__return" href="/services">BACK TO SERVICES</a>
                        <div class="section__title">FAQs</div>

                        <div class="faqs__holder">
                            <ul class="collapsible">
                                {faqs.map(faq => {
                                    return(
                                        <li class="faqs__item">
                                            <div class="collapsible-header">
                                                <div>{faq.title}</div>
                                                <img src="/assets/images/icons/arrow-down.svg" alt="Down" /></div>
                                            <div class="collapsible-body">
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: faq.content }}
                                                />
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </section>
            </Layout>
        </div>
    )
}

export default FaqPage;

